<template>
  <div class="Grid-layout Inspiration-insert" v-if="cards?.length">
    <ux-atoms-typo as="h2" class="Inspiration-insert__title" color="dark" v-if="hasTitle" variant="expressive-heading-03">
      {{ $t('ux.molecules.inspirationInset.title') }}
    </ux-atoms-typo>
    <ul class="Inspiration-insert__list">
      <li :key="`card-${card.contentfulId}`" class="Inspiration-insert__list-item" v-for="(card, index) in limitCards">
        <ux-molecules-card-inspiration v-bind="formatedCard(card)" :variant="variantType(index)" />
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { EditorialContent } from '~/graphql';
import { smallSrc } from '~/helpers/imageSizes';

import { CardInspirationProps } from '../CardInspiration/CardInspiration.vue';

export interface InspirationInsertProps {
  cards: EditorialContent[] | null;
  hasTitle: boolean;
  title?: string;
}

const props = withDefaults(defineProps<InspirationInsertProps>(), {
  cards: null,
  hasTitle: false,
  title: undefined
});

const { locale, t } = useI18n();
const localePath = useLocalePath();

const limitCards = computed(() => props.cards?.slice(0, 3));

const formatedCard = (card: EditorialContent): CardInspirationProps => {
  const slug: string = card?.slugs?.[locale.value as keyof typeof card.slugs] ?? '';

  return {
    alt: card?.coverImage?.alt ?? '',
    href: localePath(`/${card?.variant?.toLocaleLowerCase()}/${slug}`),
    label: t(`ux.molecules.inspirationFilterContent.${card?.variant?.toLocaleLowerCase()}`) ?? '',
    src: smallSrc(card?.coverImage?.src, card?.coverImage?.srcSet),
    teaser: card?.subtitle ?? '',
    title: card?.title ?? ''
  };
};

const variantType = (index: number) => {
  return index === 0 ? 'large' : 'small';
};
</script>
<style lang="scss" scoped>
@use 'InspirationInsert.scss';
</style>
